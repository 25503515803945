/*@import "../node_modules/bootstrap/scss/functions";

$primary: #ed1b24;
$navbar-light-color: #000;
$navbar-light-active-color: $primary;

$border-radius: .1rem;
$btn-border-radius: $border-radius;
$border-radius-lg: $border-radius;
$border-radius-sm: $border-radius;



@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/code";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/input-group";
@import "../node_modules/bootstrap/scss/custom-forms";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/jumbotron";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/media";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/print";
// @import "../node_modules/bootstrap/scss/bootstrap";
*/


$primary: #32586d;
$secondary: #ca9e45;
$secondary: #c9d4c7;
$success: #c3d2c8;
$info: #185190;
$warning: #ca9e45;
$danger: #ddbfb7;
$dark: #232323;
$medium: #b4cded;
$light: #eae9ec;
$lighter: #fafafa;
$white: #ffffff;

$sem: #ddbfb7;
$komp: #232323;
$medium: #b4cded;
$light: #eae9ec;
$lighter: #fafafa;
$white: #ffffff;


$bg-sem: #b1c78c;
$bg-int: #89abd6;
$bg-komp: #dab960;
$bg-per: #6ec5d4;
$bg-utb: #b17fc7;
$default: #32586d;
$bg-komp: #da7860;
$bg-default: #062c37;


$link-hover-decoration: none;
$border-radius: 0;
$border-radius-sm: 0;
$btn-border-radius: 3rem;
$input-btn-padding-x: 1rem;

$card-cap-bg: white;

// $modal-sm: 300px !default;
// $modal-md: 500px !default;
// $modal-lg: 800px !default;
// $modal-xl: 1000px !default;

// $table-hover-bg: $lighter;

$input-group-addon-bg: white;

// @import url('https://fonts.googleapis.com/css2?family=Spartan:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');

$font-family-sans-pro: "Source Sans Pro", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
$headings-font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
$headings-font-weight: 500;

// $font-family-sans-serif: "Spartan", sans-serif;
// $headings-font-family: "Spartan", sans-serif;

@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/accordion";
@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/offcanvas";
@import "../node_modules/bootstrap/scss/placeholders";

@import "../node_modules/bootstrap/scss/helpers/";
@import "../node_modules/bootstrap/scss/utilities/api";
// @import "../node_modules/bootstrap/scss/input-group";
// @import "../node_modules/bootstrap/scss/custom-forms";
// @import "../node_modules/bootstrap/scss/jumbotron";
// @import "../node_modules/bootstrap/scss/media";
// @import "../node_modules/bootstrap/scss/print";

@import '~bootstrap/dist/css/bootstrap.min.css';

::selection {
  background-color: $primary;
  color: #fff;
}

.form-control:focus {
  border: none;
  box-shadow: none;
  // border-color: #ff80ff;
  // box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(255, 100, 255, 0.5);
}

label,
legend {
  font-weight: bold;
}

.is-clickable {
  cursor: pointer;
}

// .footer {
//   background-color: $dark;
//   padding: 2rem;
//   color: $light;
//   text-align: center;
//   font-size: 0.75rem;
// }

.fc-timeline-event {
  font-size: 1rem;
  padding: 4px 8px !important;
}


/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #062c37;
  border-radius: 0;
}

.btn-outline-primary {
  color: #000;
  background-color: #fff;
  border-color: #062c37;
  border-radius: 0;
}

html,
body,
nav {
  background-color: #062c37;
  color: #fff;
  font-family: $font-family-sans-pro;
  // font-weight: 200;
}

// html {
//   height: -webkit-fill-available;
// }

// html, body {
//   display: flex;
//   flex-direction: column;
//   margin: 0 auto;
//   height: -webkit-fill-available;
//   height: 100vh;
// }

html,
body {
  // height: 100vh;
  // height: 100dvh;
  height: 100%;
  width: 100%;
  // overflow: hidden;
}

// .body-wrapper {
//   height: 100%;
//   width: 100%;
//   overflow: hidden !important;
//   // position: relative;
// }

.offcanvas {
  width: 320px !important;
  min-width: 320px !important;
  margin-top: 60px !important;
  margin-inline: 0 !important;
  background-color: #062c37 !important;
  overflow-x: auto;
}

.offcanvas-end {
  border: none !important;
}

.offcanvas-body {
  padding-block: 0 !important;
  padding-inline: 16px !important;
  margin-inline: 12px !important;
  // margin-inline: 16px;
}

.offcanvas-backdrop {
  margin-top: 60px;
}

.pointer {
  cursor: pointer;
  color: #fff !important;
  opacity: 1 !important;
  transition: all 0.2s ease-out;
}

.pointer:hover {
  opacity: 0.75 !important;
  background-color: transparent !important;
  transition: all 0.2s ease-in;
}

.nav-pills .nav-link {
  background-color: transparent;
  border-bottom: 2px solid transparent;
}

.nav-pills .nav-link.active {
  background-color: transparent;
  border-bottom: 2px solid #ea582d;
}

.notifier__container * {
  box-sizing: border-box;
}

.notifier__container-list {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.notifier__notification {
  background: #fff;
  padding: 12px 60px 12px 20px;
  border-radius: 2px;
  font-size: 14px;
  max-width: 250px;
  display: block;
  position: fixed;
  visibility: hidden;
  z-index: 10000;
  will-change: transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
}

.notifier__notification p {
  padding-bottom: 0;
  margin-bottom: 0;
}

.notifier__notification-button {
  position: absolute;
  top: 5px;
  right: 0;
  border: 0;
  background: transparent;
  font-size: 12px;
  opacity: 0.5;
}

.notifier__notification--default {
  background-color: #FFF;
  color: #000000;
}

.notifier__notification--info {
  background-color: #FFF;
  color: #000000;
}


.notifier__notification--info .notifier__notification-button-icon {
  fill: #FFF;
}

.notifier__notification--default .notifier__notification-button-icon {
  fill: #FFF;
}

.notifier__notification--success {
  background-color: #FFF;
  color: #5db45d;
  font-weight: bold;
}

.notifier__notification--success .notifier__notification-button-icon {
  fill: #FFF;
}

.notifier__notification--error {
  background-color: #D9534F;
  color: #FFF;
}

.notifier__notification--error .notifier__notification-button-icon {
  fill: #FFF;
}

.notifier__notification--warning {
  background-color: #f0ad4e;
  color: #FFF;
}

.notifier__notification--warning .notifier__notification-button-icon {
  fill: #FFF;
}
